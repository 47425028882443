<template>
  <div class="grid">
    <Toast />

    <div class="col-12" v-if="usuarioData">
      <h6 v-if="usuarioData.id">Editando Usuário {{ usuarioData.id}}</h6>
      <h6 v-else>Novo Usuário</h6>
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Salvar" icon="pi pi-save" class="p-button-success mr-2" @click="saveUsuario" :loading="loadingBtnSave" />
            <Button label="Exportar" icon="pi pi-download" class="mr-2 p-button-outlined" @click="exportCSV($event)"  />
            <Button label="Importar" icon="pi pi-upload" class="mr-2 p-button-outlined" @click="exportCSV($event)"  />
          </div>
        </template>
        <template v-slot:end>
          <Button label="Voltar" icon="pi pi-backward" class="p-button-outlined mr-2" @click="$router.go(-1)" />
        </template>
      </Toolbar>
    </div>

    <div class="col-12" >

      <div  class="custom-skeleton p-4" v-if="loadingData === true">
        <Skeleton width="100%" height="50px"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
      </div>

      <TabView v-if="loadingData === false" >

        <TabPanel>

          <template #header>
            <span>Dados Principais</span>
            <i class="pi pi-user ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">

            <div class="field col-3 md:col-3">
              <label for="name">Nome</label>
              <InputText
                  id="name"
                  :class="!usuarioData.name ? 'p-invalid' : ''"
                  type="text" v-model="usuarioData.name"/>
              <small class="p-invalid" v-if="loadingBtnSave && !usuarioData.name">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="tipo">Perfil</label>
              <Dropdown
                  id="tipo"
                  v-model="usuarioData.tipo"
                  :options="perfilItems"
                  optionLabel="name"
                  optionValue="code"
                  :class="!usuarioData.tipo ? 'p-invalid' : ''"
                  placeholder="Selecione o perfil do usuário">
              </Dropdown>
              <small class="p-invalid" v-if="loadingBtnSave && !usuarioData.tipo">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="email">E-mail</label>
              <div class="p-inputgroup">
                <InputText
                    id="email"
                    type="email"
                    :class="!usuarioData.email ? 'p-invalid' : ''"
                    v-model="usuarioData.email"/>
                <Button icon="pi pi-send" class="p-button" @click="enviarEmail()"/>
              </div>
              <small class="p-invalid" v-if="loadingBtnSave && !usuarioData.email">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="tel1">Telefone</label>
              <div class="p-inputgroup">
                <InputMask id="tel1" mask="(99) 9.9999-9999" v-model="usuarioData.tel1"></InputMask>
                <Button icon="pi pi-whatsapp" class="p-button" @click="enviarZap()"/>
              </div>
            </div>


            <div class="field col-3 md:col-3">
              <label for="cnpj">CPF / CNPJ</label>
              <div class="p-inputgroup">
                <InputText id="cnpj" v-model="usuarioData.cnpj"></InputText>
              </div>
            </div>

            <div class="field col-3 md:col-3">
              <label for="cidade">Cidade</label>
              <AutoComplete placeholder="Pesquisar cidade" :dropdown="true" :multiple="false" v-model="selectedAutoValueCidade" :suggestions="autoFilteredCidade" @complete="searchCidade($event)" field="nome" @item-select="selecionaCidade($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 60%">{{slotProps.item.nome}}</div>
                    <div style="width: 20%" class="text-right">{{ formatDecimal(slotProps.item.anual) }}</div>
                    <div style="width: 10%"  class="text-right">{{slotProps.item.estado}}</div>
                  </div>
                </template>
              </AutoComplete>
            </div>

            <div class="field col-3 md:col-3">
              <label for="estado">Estado</label>
              <Dropdown id="estado" v-model="usuarioData.estado" :options="estadoItems" optionLabel="name"  optionValue="code" placeholder="Selecionar estado"></Dropdown>
            </div>

            <div class="field col-3 md:col-3">
            </div>

            <div class="field col-3 md:col-3">
              <label for="password">Senha</label>
              <InputText
                  id="password"
                  type="password"
                  :placeholder="usuarioData.id ? 'deixar em branco para não alterar' : ''"
                  :class="!usuarioData.id && !usuarioData.password ? 'p-invalid' : ''"
                  v-model="usuarioData.password"/>
              <small class="p-invalid" v-if="loadingBtnSave && !usuarioData.id && !usuarioData.password">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <h5>Status</h5>
              <InputSwitch id="status" v-model="usuarioData.status" />
            </div>


            <Divider layout="horizontal">
              <b>Parâmetros</b>
              <i class="pi pi-cog ml-2"></i>
            </Divider>

            <div class="field col-3 md:col-3">
              <label for="percomi">Comissão</label>
              <InputNumber id="percomi" v-model="usuarioData.percomi" prefix="% " mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>

            <div class="field col-3 md:col-3">
              <label for="cusfix">Custo Fixo</label>
              <InputNumber id="cusfix" v-model="usuarioData.cusfix" mode="currency" currency="BRL" locale="pt-BR" />

            </div>




          </div>
        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Leads</span>
            <i class="pi pi-id-card ml-2"></i>
          </template>

          <DataTable v-if="leadsData" :value="leadsData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/leads')" />
              </template>
            </Column>
            <Column field="code" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column field="representante" header="Representante" :style="{width:'200px'}"></Column>
            <Column field="inventoryStatus" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'lead-badge status-' + (data.inventoryStatus ? data.inventoryStatus.toLowerCase() : '')">{{data.inventoryStatus}}</span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.price)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Orçamentos</span>
            <i class="pi pi-eye ml-2"></i>
          </template>

          <DataTable v-if="orcamentosData" :value="orcamentosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/orcamentodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'orcamento-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Vendas</span>
            <i class="pi pi-shopping-cart ml-2"></i>
          </template>

          <DataTable v-if="pedidosData" :value="pedidosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/pedidodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'pedido-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


        <TabPanel>

          <template #header>
            <span>Obras</span>
            <i class="pi pi-briefcase ml-2"></i>
          </template>

          <DataTable v-if="obrasData" :value="obrasData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/obradetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'obra-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Projetos</span>
            <i class="pi pi-sitemap ml-2"></i>
          </template>


          <DataTable v-if="projetosData" :value="projetosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/projetodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'projeto-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>


        <TabPanel>

          <template #header>
            <span>Financeiro</span>
            <i class="pi pi-money-bill ml-2"></i>
          </template>
          Em andamento
        </TabPanel>
      </TabView>
    </div>

    <div class="col-12 md:col-6 lg:col-4" v-if="usuarioData">
      <div class="card widget-tasks">
        <div class="card-header block mb-3 px-2 ">
          <span class="block font-bold mb-3">Criado</span>
        </div>
        <p class="px-2">{{ formatDateTime(usuarioData.created_at) }} </p>
        <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
          <div class="footer-left flex align-items-center">
            <span class="clock"><i class="pi pi-clock mr-1"></i>{{ formatDateExt(usuarioData.created_at) }}.
              <span class="font-bold">{{ formatDateDifDays(usuarioData.created_at) }} </span>
            </span>
          </div>
          <div class="footer-right">
            <span class="badge block w-min font-bold px-2 py-1 border-1 border-solid ">{{ usuarioData.created_by ? usuarioData.created_by : 'admin@admin.com.br' }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6 lg:col-4" v-if="usuarioData">
      <div class="card widget-tasks">
        <div class="card-header block mb-3 px-2 ">
          <span class="block font-bold mb-3">Último Login</span>
        </div>
        <p class="px-2">{{ formatDateTime(usuarioData.last_login) }} </p>
        <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
          <div class="footer-left flex align-items-center">
            <span class="clock"><i class="pi pi-clock mr-1"></i>{{ formatDateExt(usuarioData.last_login) }}.
              <span class="font-bold">{{ formatDateDifDays(usuarioData.last_login) }}</span>
            </span>
          </div>
          <div class="footer-right">
          </div>
        </div>
      </div>
    </div>


    <div class="col-12 md:col-6 lg:col-4" v-if="usuarioData">
      <div class="card widget-tasks">
        <div class="card-header block mb-3 px-2 ">
          <span class="block font-bold mb-3">Alterado</span>
        </div>
        <p class="px-2">{{ formatDateTime(usuarioData.updated_at) }} </p>
        <div class="card-footer flex flex-wrap align-items-center justify-content-between pt-3">
          <div class="footer-left flex align-items-center">
            <span class="clock"><i class="pi pi-clock mr-1"></i>{{ formatDateExt(usuarioData.updated_at) }}.
              <span class="font-bold">{{ formatDateDifDays(usuarioData.updated_at) }} </span>
            </span>
          </div>
          <div class="footer-right">
            <span class="badge block w-min font-bold px-2 py-1 border-1 border-solid ">{{ usuarioData.updated_by ? usuarioData.updated_by : 'admin@admin.com.br' }}</span>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>
<script>
import UsuarioService from "@/service/UsuarioService";
import moment from 'moment'

export default {
  components: {

  },
  data() {
    return {
      telhorieItems: [
        {code: 'NO', name: 'Norte'},
        {code: 'SU', name: 'Sul'},
        {code: 'LE', name: 'Leste'},
        {code: 'OE', name: 'Oeste'},
        {code: 'ND', name: 'Nordeste'},
        {code: 'SD', name: 'Sudoeste'},
        {code: 'NR', name: 'Noroeste'},

      ],
      geradorItems: [
        {code: 'SIM', name: 'Sim'},
        {code: 'NAO', name: 'Não'},

      ],
      telhestrItems: [
        {code: 'MAD', name: 'Madeira'},
        {code: 'MET', name: 'Metal'},
        {code: 'TER', name: 'Terça'},
        {code: 'OUT', name: 'Outro'},
      ],
      telhadoItems: [
        {code: 'FIB', name: 'Fibrocimento'},
        {code: 'COL', name: 'Colonial'},
        {code: 'ZIN', name: 'Zinco'},
        {code: 'OUT', name: 'Outro'},
      ],
      tensaoItems: [
        {code: '127', name: '127V'},
        {code: '220', name: '220V'},
        {code: '380', name: '380V'},
      ],
      sistemaItems: [
        {code: 'MN', name: 'Monofásico'},
        {code: 'BI', name: 'Bifásico'},
        {code: 'TR', name: 'Trifásico'},
      ],
      radioValueEspaco: null,
      radioValueAumento: null,
      radioValueInternet: null,
      radioValueGrupo: null,

      selectedAutoValueCidade: null,
      autoFilteredCidade: [],
      autoValueCidade: [],
      loadingCEP: false,
      loadingData: true,
      loadingBtnSave: false,
      loadingBtn: [false, false, false],
      pedidosData: null,
      orcamentosData: null,
      obrasData: null,
      projetosData: null,

      loadingLeads: true,
      leadService: null,
      leadsData: null,
      usuarioData: null,
      perfilItems: [
        {code: 'admin', name: 'Administrador'},
        {code: 'super', name: 'Supervisor'},
        {code: 'geren', name: 'Gerente'},
        {code: 'repre', name: 'Representante'},
      ],

      estadoItems: [
        {code: 'AC', name: 'Acre'},
        {code: 'AL', name: 'Alagoas'},
        {code: 'AP', name: 'Amapá'},
        {code: 'AM', name: 'Amazonas'},
        {code: 'BA', name: 'Bahia'},
        {code: 'CE', name: 'Ceará'},
        {code: 'DF', name: 'Distrito Federal'},
        {code: 'ES', name: 'Espírito Santo'},
        {code: 'GO', name: 'Goiás'},
        {code: 'MA', name: 'Maranhão'},
        {code: 'MT', name: 'Mato Grosso'},
        {code: 'MS', name: 'Mato Grosso do Sul'},
        {code: 'MG', name: 'Minas Gerais'},
        {code: 'PA', name: 'Pará'},
        {code: 'PB', name: 'Paraíba'},
        {code: 'PR', name: 'Paraná'},
        {code: 'PE', name: 'Pernambuco'},
        {code: 'PI', name: 'Piauí'},
        {code: 'RJ', name: 'Rio de Janeiro'},
        {code: 'RN', name: 'Rio Grande do Norte'},
        {code: 'RS', name: 'Rio Grande do Sul'},
        {code: 'RO', name: 'Rondônia'},
        {code: 'RR', name: 'Roraima'},
        {code: 'SC', name: 'Santa Catarina'},
        {code: 'SP', name: 'São Paulo'},
        {code: 'SE', name: 'Sergipe'},
        {code: 'TO', name: 'Tocantins'},
      ],
      dropdownItem: null,

    }
  },

  created() {
    this.usuarioService = new UsuarioService();
    // moment.setlocale(LC_TIME, 'pt_BR', 'pt_BR.utf-8', 'pt_BR.utf-8', 'portuguese');
    // moment.date_default_timezone_set('America/Sao_Paulo');
    moment.locale('pt_BR');
  },


  mounted() {

    let idusuario = this.$route.params.id
    this.usuarioService.getssusuario(idusuario).then((data) => {

      if (data === 'nao_permitido') {
        this.$router.replace({ path: '/acessorestrito' })
      }else {
        this.usuarioData = data[0]
        console.log('getUsuario')
        console.log(data)
        this.loadingData = false
        this.selectedAutoValueCidade = this.usuarioData.cidade
      }
    })

    this.usuarioService.getLeads().then((data) => {
      console.log('usuarioDetalhe_0')
      this.leadsData = data
      this.loadingLeads = false
      // console.log(this.leadsData)
      this.leadsData.forEach(lead => lead.date = new Date(lead.date));
    });

    this.usuarioService.getOrcamentos().then((data) => {
      this.orcamentosData = data
      // console.log(this.orcamentosData)
      this.orcamentosData.splice(0,6)
      // console.log(this.orcamentosData)
      this.orcamentosData.forEach(orcto => orcto.date = new Date(orcto.date));
    });

    this.usuarioService.getPedidos().then((data) => {
      this.pedidosData = data
      // console.log(this.pedidosData)
      this.pedidosData.splice(0,3)
      // console.log(this.pedidosData)
      this.pedidosData.forEach(pedido => pedido.date = new Date(pedido.date));
    });

    this.usuarioService.getObras().then((data) => {
      this.obrasData = data
      // console.log(this.pedidosData)
      this.obrasData.splice(0,5)
      // console.log(this.pedidosData)
      this.obrasData.forEach(obra => obra.date = new Date(obra.date));
    });

    this.usuarioService.getProjetos().then((data) => {
      this.projetosData = data
      // console.log(this.pedidosData)
      this.projetosData.splice(0,8)
      // console.log(this.pedidosData)
      this.projetosData.forEach(obra => obra.date = new Date(obra.date));
    });



  },

  methods: {

    validEmail (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(email) === false){
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Endereço de e-mail inválido, favor corrigir', life: 3000});
      }
      return re.test(email);
    },

    validNome (nome) {
      if (nome && nome.length >= 8){
        return true
      }else{
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Tamanho de nome deve ser maior ou igual a 8 caracteres, favor corrigir', life: 3000});
      }
    },

    validSenha (senha) {
      //let regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*()_+^&}{:;?.])(?:([0-9a-zA-Z!@#$%;*(){}_+^&])(?!\1)){6,}$/

      let regexNum = /\d/
      let regexLet = /[a-zA-Z]/
      //regex.test(str);
      //
      // console.log('regex.test(senha)')
      // console.log(regexNum.test(senha))
      // console.log('letras')
      // console.log(regexLet.test(senha))

      if ((this.usuarioData.id && !this.usuarioData.password) || (regexNum.test(senha) && regexLet.test(senha) && senha.length >= 8)) {
        return true
      } else {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Senha deve possuir no mínimo 8 caracteres, obrigatório letras, números e caractere especial, favor corrigir', life: 5000});
        return false
      }
    },

    onUpload() {

    },

    selecionaCidade(event){
      console.log('selecionaCidade')
      // console.log(event.value)
      this.usuarioData['cidade'] = event.value.nome
      this.usuarioData['estado'] = event.value.estado
    },

    searchCidade(event){
      console.log('searchCidade')
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCidade = [...this.autoValueCidade];
        }
        else {
          // console.log('vai pesquisar')
          this.usuarioService.getPesqCidade().then((data) => {
            // console.log('getPesqCidade')
            // console.log(data)
            this.autoValueCidade = data
            this.autoFilteredCidade = this.autoValueCidade.filter((cidade) => {
              return cidade.nome.toLowerCase().startsWith(event.query.toLowerCase());
            });
          });

        }
      }, 250);
    },

    pesquisarCEP() {
      if (!this.usuarioData.cep) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de cep inválido, favor corrigir', life: 3000});
      }else{
        this.loadingCEP = true
        let cepFormated = this.usuarioData.cep.replace('.','').replace('-', '');
        this.usuarioService.getPesquisaCEP(cepFormated).then((dataCEP) => {
          console.log('getPesquisaCEP')
          console.log(dataCEP)

          if (dataCEP.cep){
            this.usuarioData.bairro = dataCEP.bairro
            this.usuarioData.compl = dataCEP.complemento
            this.usuarioData.cidade = dataCEP.localidade
            this.usuarioData.rua = dataCEP.logradouro
            this.usuarioData.estado = dataCEP.uf
          }else{
            this.$toast.add({severity:'warn', summary: 'Validação', detail:'Retorno de consulta inválido, favor preencher manualmente os dados', life: 3000});
          }
          this.loadingCEP = false
        });
      }


    },

    enviarEmail(){
      if (!this.usuarioData.email) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de e-mail inválido, favor corrigir', life: 3000});
      }else{
        window.open("mailto:"+this.usuarioData.email);
      }
    },

    enviarZap(){
      if (!this.usuarioData.tel1) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Tamanho do número de telefone inválido, favor corrigir', life: 3000});
      }else{
        window.open('https://api.whatsapp.com/send?phone=5527999722245', '_blank');
      }
    },

    loadBtn(index) {
      this.loadingBtn[index] = true;
      setTimeout(() => this.loadingBtn[index] = false, 1000);
    },

    saveUsuario() {
      this.loadingBtnSave = true
      if (this.usuarioData.tipo && this.validSenha(this.usuarioData.password) && this.validEmail(this.usuarioData.email) && this.validNome(this.usuarioData.name)){
        this.usuarioService.savessusuario({
          'usuario': this.usuarioData,
        }).then((dataUser) => {
          console.log('savessusuario')
          console.log(dataUser)
          this.loadingBtnSave = false
          if (!this.usuarioData.id) {
            this.usuarioData.id = dataUser[0].id
            setTimeout(() => this.$router.replace({ path: '/usuariodetalhe/' + dataUser[0].id }), 2000);
          }
          this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 2000})

        }).catch((err) => {
          console.log('err')
          // console.log(err.response.data.errors)
          let obj = err.response.data.errors
          let messages = ''
          Object.keys(obj).forEach(function(key){
            messages = messages + obj[key][0] + ' | '
          })
          this.$toast.add({severity:'warn', summary: 'Erro de Validação', detail: messages, life: 5000})
          this.loadingBtnSave = false
        })
      } else{
        setTimeout(() => {
          this.loadingBtnSave = false
        }, 5000)
      }
    },

    formatDate(value) {
      // console.log('value formatDate')
      // console.log(value)
      // console.log(moment())
      const format = "DD-MM-YYYY"
      var date = new Date(value);
      let dateTime = moment(date).format(format);
      // console.log(dateTime1)
      return dateTime
    },

    formatDateTime(value) {
      // console.log('value formatDate')
      // console.log(value)
      // console.log(moment())
      const format = "DD/MM/YYYY HH:mm:ss"
      var date = new Date(value);
      let dateTime = moment(date).format(format);
      // console.log(dateTime1)
      return dateTime
    },

    formatDateExt(value) {
      let dateD = moment(new Date(value)).format('DD')
      let dateM = moment(new Date(value)).format('MMMM')
      return dateD + ' de ' + dateM
    },

    formatDateDifDays(value) {
      let dateAnt = moment(new Date(value))
      let dateNow = moment(new Date())
      let diferD = dateNow.diff(dateAnt, 'days')
      let formatTextD = 'dia'
      if (diferD > 1){
        formatTextD = 'dias'
      }
      let diferH = dateNow.diff(dateAnt, 'hours')
      let formatTextH = 'hora'
      if (diferH > 1){
        formatTextH = 'horas'
      }
      return diferD + ' ' + formatTextD + ' (' + diferH + ' ' + formatTextH + ')'
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },

    formatCurrency(value) {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
